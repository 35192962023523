var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"mb-10",attrs:{"fluid":""}},[_c('v-row',{staticClass:"ml-7",staticStyle:{"width":"96%"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-toolbar',{staticStyle:{"background":"#f5f5f5"},attrs:{"elevation":"0","height":"80px"}},[_c('div',[_c('p',{staticClass:"mb-0 text-h4 font-weight-bold"},[_vm._v(" Mothersite Product Report ")])]),_c('v-spacer'),_c('v-menu',{attrs:{"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"pt-5",staticStyle:{"width":"50px"},attrs:{"label":"Start Date","prepend-icon":"mdi-calendar","readonly":"","dense":""},model:{value:(_vm.start_date),callback:function ($$v) {_vm.start_date=$$v},expression:"start_date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{staticClass:"mt-0",attrs:{"prev-icon":"mdi-skip-previous","next-icon":"mdi-skip-next","color":"purple darken-2"},on:{"input":_vm.onStartDateChange},model:{value:(_vm.start_date),callback:function ($$v) {_vm.start_date=$$v},expression:"start_date"}})],1),_c('v-menu',{attrs:{"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"pt-5",staticStyle:{"width":"50px"},attrs:{"label":"End Date","prepend-icon":"mdi-calendar","readonly":"","dense":""},model:{value:(_vm.end_date),callback:function ($$v) {_vm.end_date=$$v},expression:"end_date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{staticClass:"mt-0",attrs:{"min":_vm.start_date,"prev-icon":"mdi-skip-previous","next-icon":"mdi-skip-next","color":"purple darken-2"},on:{"input":_vm.onEndDateChange},model:{value:(_vm.end_date),callback:function ($$v) {_vm.end_date=$$v},expression:"end_date"}})],1)],1),_c('v-data-table',{staticClass:"elevation-1",staticStyle:{"background":"#f5f5f5"},attrs:{"headers":_vm.headers,"items":_vm.products,"search":_vm.search,"loading":_vm.loading,"loading-text":"Please wait..."},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('b',[_vm._v("Product Id: ")]),_vm._v(" "+_vm._s(item.product_id)+" "),_c('br'),_c('b',[_vm._v("Product Name: ")]),_vm._v(" "+_vm._s(item.product_name)+" "),_c('br')])]}},{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"mt-2",staticStyle:{"background":"#f5f5f5"},attrs:{"elevation":"2"}},[_c('v-spacer'),_c('div',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","filled":"","rounded":"","dense":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}})],1)]},proxy:true},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"shaped":"","text":"","color":"#FF7743"},on:{"click":function($event){return _vm.viewInvoice(item)}}},[_vm._v(" Invoice ")])]}},{key:"footer",fn:function(){return [_c('hr'),_c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"9"}}),_c('v-col',[_c('v-card',{staticClass:"mx-5 my-0",staticStyle:{"background":"#f5f5f5"},attrs:{"elevation":"0"}},[_c('v-card-text',{staticClass:"text-right"},[_c('p',{staticClass:"font-weight-bold mb-0"},[_vm._v(" Total Profit : "+_vm._s(_vm.reportInfo.total_profit)+" ")])])],1)],1)],1)],1)]},proxy:true}])},[_c('v-spacer')],1)],1)],1)],1),_c('v-snackbar',{attrs:{"centered":"","color":_vm.color},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"white","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.text)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }